import { env } from "$env/dynamic/public";
import { PUBLIC_BUILD_VERSION, PUBLIC_ENV, PUBLIC_SENTRY_LOG_RATE } from "$env/static/public";
import { registerError } from "$lib/serv/error-handling";
import { init, handleErrorWithSentry, captureException } from '@sentry/sveltekit';
import type { HandleClientError } from "@sveltejs/kit";

const isProd = PUBLIC_ENV.toLowerCase() === 'prod';
const errorLogRate = parseFloat(env.PUBLIC_SENTRY_LOG_RATE ?? PUBLIC_SENTRY_LOG_RATE);
const sampleRate = isNaN(errorLogRate) ? (isProd ? 0.03 : 1) : errorLogRate;

init({
  dsn: 'https://e744720287f44599be2e8a813570e809@o307890.ingest.sentry.io/6758732',
  enableTracing: false,
  //tracesSampleRate: PUBLIC_ENV.toLowerCase() === 'prod' ? 0.01 : 1.0,
  sampleRate,

  enabled: !!sampleRate,//&& env.TEST !== 'true'
  environment: PUBLIC_ENV,
  release: PUBLIC_BUILD_VERSION,
  maxBreadcrumbs: 20,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  //replaysSessionSampleRate: PUBLIC_ENV.toLowerCase() === 'prod' ? 0.01 : 1.0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  //replaysOnErrorSampleRate: PUBLIC_ENV.toLowerCase() === 'prod' ? 0.2 : 1.0,
  
  ignoreErrors: [ // string -> partial match; RegExp -> specific match
    // Tiktok webview issue causing Sentry to break - we cannot fix code we don't control
    'Cannot read properties of undefined (reading \'domInteractive\')',
    // .NET embedded Chromium issue (could be Outlook generating link previews or something similar)
    /Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/,
    // recaptcha issue - see https://github.com/getsentry/sentry-javascript/issues/2514
    'UnhandledRejection: Non-Error promise rejection captured with value: Timeout'
  ],
});

const errorHandler:HandleClientError = ({ error, event }) => {
  let message: string; // for SvelteKit and other UI uses
  let er: Error; // for Sentry
  const ctx: Parameters<typeof captureException>[1] = { extra: { event } };
  if (error instanceof Error) {
    // NATIVE ERROR
    er = error;
    message = error.message;
  } else if (typeof error === 'object' && error && 'friendly_message' in error && typeof error.friendly_message === 'string') {
    // HTTP ERROR RESPONSE
    message = error.friendly_message;
    er = new Error(message);
    ctx.extra = { ...ctx.extra, ...error }; // HTTP error response with platform error body
  } else if (typeof error === 'object' && error && 'message' in error && typeof error.message === 'string') {
    // SOME OBJECT IMITATING AN ERROR
    message = error.message;
    er = new Error(message)
    if ('stack' in error && typeof error.stack === 'string') er.stack = error.stack;
    ctx.extra = { ...ctx.extra, ...error }; // send the object as extra so we can improve the error handling
  } else {
    // UNKNOWN OBJECT
    message = 'Woops!';
    er = new Error(message);
  }
  // send to Sentry
  const errorId = captureException(er, {extra: { event }});
  // list for the UI to display if needed
  registerError(message, { error, event }, errorId);
  return { message, errorId }
}

// TODO: figure out how we can smoothen the UX. Perhaps start by creating a route that will error to see how it behaves.
export const handleError = handleErrorWithSentry(errorHandler);

